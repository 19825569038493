/*
*
* RD Google Map
*/

.gm-style-pbt {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: $gray-900;
	color: $white;
	padding: 5px 14px;
	font-size: 16px;
	border-radius: 3px;
	box-shadow: $shadow-area-2;
	width: 100%;
	text-align: center;
	max-width: 300px;
}

.google-map-markers {
	display: none;
}

.google-map-container {
	width: 100%;
}

.google-map-container {
	color: $black;
	width: 100%;
	height: 100%;

	.google-map {
		height: $map-height;
		position: relative;

		@include media-breakpoint-up(sm) {
			height: $map-xs-height;
		}

		@include media-breakpoint-up(md) {
			height: $map-md-height;
		}

		@include media-breakpoint-up(lg) {
			height: $map-lg-height;
		}

		@include media-breakpoint-up(xl) {
			height: 100%;
			position: absolute !important;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

}
