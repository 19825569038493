/* Do not edit main.css edit styles.css or main.scss insted */
@import url('https://fonts.googleapis.com/css?family=Questrial');

//
// Main styles
//
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";
@import "variables-custom";
@import "mixins";
@import "reset";

@import "main-styles";
@import "custom-style";
