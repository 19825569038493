@media(min-width: 993px) and (max-width: 1400px){
  .rd-navbar-static .rd-search {
    right: 44%;
  }
}
ul#tabs-menu {
  margin: 30px 0;
  li{
    a{
      color: #bebebe;
    }
    a:active{
      background-color: #fff;
    }
  }
}
.breadcrumbs-custom-main h2 {
  font-size: 45px;
  font-weight: bold;
}
#header .rd-navbar-wrap {
  height: auto !important;
}
.elementor-text-editor.elementor-clearfix ul li {
  list-style-type: disc;
}
@media (max-width: 767px) {
  .about_page div div.elementor-row {
    flex-direction: column-reverse;
  }
}
.widget .search {
  background: transparent;
}
.post-template-default{
  a,p{
    color: #bebebe;
  }
}
#rd-navbar-collapse-content-1{
  a{
    color: #fff;
  }
  a:hover{
    color: #6c757d;
  }
}

ul#menu-tabs-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    a {
      color: #bebebe;
      display: block;
      padding: 0.5rem 1rem;
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    a:hover{
      border: 1px solid #f7f7f7;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    a:active {
      background-color: #fff !important;
    }
  }
  li.active {
    a {
      background-color: #fff;
    }
  }
}
#tabs-menu li.active {
  a {
    background-color: #fff;
  }
}
.pagination a.page-link {
  color: #212121;
}
.wpcf7-validation-errors {
  color: #212121 !important;
}
.wpcf7-mail-sent-ok {
  color: #61ce70 !important;
}