/*
* @subsection  RD Navbar Static
*/
// - li first level and li
// - link first level and link
// - menus
// - dropdowns
// - megamenus
// - rd-search
// - submenus

.rd-navbar-static {
	display: block;

	// RD Navbar Nav
	//
	// RD Nav item 1-st level
	.rd-nav-item {
		display: inline-block;
		&.focus,
		&.opened {
			.rd-nav-link {
				color: $rd-navbar-nav-hover-color;
				background: $rd-navbar-nav-hover-background;
			}

			> .rd-navbar-submenu-toggle {
				color: $rd-navbar-nav-hover-color;
			}
		}

		&.active {
			.rd-nav-link {
				color: $rd-navbar-nav-active-color;
				background: $rd-navbar-nav-active-background;
			}

			> .rd-navbar-submenu-toggle {
				color: $rd-navbar-nav-active-color;
			}
		}

		&.focus > .rd-navbar-submenu-toggle,
		&.opened > .rd-navbar-submenu-toggle,
		.rd-nav-link:hover + .rd-navbar-submenu-toggle {
			&::before {
				transform: rotate(180deg);
			}
		}

		> .rd-navbar-submenu-toggle {
			margin-left: 4px;
			font-family: "Material Design Icons";
			font-size: 16px;
			cursor: pointer;

			&::before {
				position: relative;
				display: inline-block;
				transition: .22s;
				content: '\f236';
			}

			&:hover {
				color: $rd-navbar-nav-hover-color;
			}
		}

		// If Have submenu
		> .rd-navbar-submenu {
			margin-top: 20px;
		}
	}

	.rd-nav-item + .rd-nav-item {
		margin-left: 35px;
	}

	// Rd Nav Link 1-st level
	.rd-nav-link {
		position: relative;
		display: inline-block;
		color: $rd-navbar-nav-color;
		transition: .25s;

		&:hover {
			color: $rd-navbar-nav-hover-color;
		}
	}

	// Rd Menu (dropdown and megamenu)
	.rd-menu {
		position: absolute;
		z-index: 15;
		display: block;
		padding: $rd-navbar-menu-padding;
		margin-top: 22px;
		margin-left: - nth($rd-navbar-menu-padding, 2);
		visibility: hidden;
		opacity: 0;
		text-align: left;
		box-shadow: 0 5px 10px 1px rgba(darken($rd-navbar-dropdown-background, 10%), .3);
		border-radius: $border-radius-lg;
		transform: translate3d(0, 30px, 0);
		@extend %rd-navbar-transition;
	}

	.rd-dropdown-item + .rd-dropdown-item,
	.rd-megamenu-list-item + .rd-megamenu-list-item {
		margin-top: 7px;
	}

	// RD Dropdown 1-st level
	.rd-navbar-dropdown {
		position: absolute;
		left: 0;
		width: $rd-navbar-dropdown-width;
		background: $rd-navbar-dropdown-background;
		z-index: 5;

		.rd-navbar-dropdown {
			top: -1px;
			left: 100%;
			z-index: 2;
			margin-top: -15px;
			margin-left: 15px;
			transform: translate3d(30px, 0, 0);

			// If the menu is on the right edge window
			&.rd-navbar-open-left {
				left: auto;
				right: 100%;
				margin-left: 0;
				margin-right: 15px;
				transform: translate3d(-30px, 0, 0);
			}
		}
	}

	.rd-dropdown-item {
		&.focus,
		&.opened {
			.rd-dropdown-link {
				color: $rd-navbar-dropdown-item-active-color;
			}
		}
	}

	.rd-dropdown-link {
		color: $rd-navbar-dropdown-item-color;

		&:hover {
			color: $rd-navbar-dropdown-item-hover-color;
		}
	}

	.rd-navbar-dropdown,
	.rd-megamenu-list {
		@include submenu-with-arrow;
	}

	// RD all megamenu
	.rd-navbar-megamenu {
		display: flex;
		left: 0;
		width: 100%;
		overflow: hidden;
		max-width: $rd-navbar-width;
		background: $rd-navbar-megamenu-background;
	}

	// Megamenu item 1st level
	.rd-megamenu-item {
		flex-basis: 50%;
		flex-grow: 1;

		+ .rd-megamenu-item {
			padding-left: 20px;
		}
	}

	.rd-megamenu-item {
		display: flex;

		.banner {
			position: relative;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			text-align: center;
			min-width: 100%;
			padding: 35px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: rgba($black, .1);
			}

			.button-primary {
				&:hover {
					background: $primary-lighten;
					border-color: $primary-lighten;
				}
			}
			
			> * {
				opacity: 0;
				visibility: hidden;
				transform: translate(0, -20%);
				transition: .22s;
			} 
			
			&:hover {
				> * {
					opacity: 1;
					visibility: visible;
					transform: translate(0, 0);
				}
			}
		}

		&:first-child,
		&:last-child {
			.banner {
				margin-top: - nth($rd-navbar-menu-padding, 1);
				margin-right: - nth($rd-navbar-menu-padding, 2);
				margin-bottom: - nth($rd-navbar-menu-padding, 1);
				margin-left: - nth($rd-navbar-menu-padding, 2);
			}
		}
	}

	// Megamenu title
	.rd-megamenu-title {
		padding-bottom: 10px;
		border-bottom: 1px solid $gray-200;
	}


	// Megamenu list link
	.rd-megamenu-list-link {
		color: $rd-navbar-megamenu-item-color;
		background: $rd-navbar-megamenu-item-background;
		&:hover {
			color: $rd-navbar-megamenu-item-hover-color;
			background: $rd-navbar-megamenu-item-hover-background;
		}
	}

	// Megamenu list offset
	* + .rd-megamenu-list { margin-top: 10px; }

	// RD all submenus
	.rd-navbar-submenu {
		&.focus,
		&.opened {
			> .rd-menu {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
			}
		}
	}

	// RD navbar search
	.rd-navbar-search {
		&.active {
			.rd-search {
				visibility: visible;
				opacity: 1;
			}

			.not-empty ~ .form-input {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		.rd-search {

		}

		.form-input {
			padding-right: $form-input-padding-horizontal;
		}

		.rd-search-form-submit {
			display: none;
		}
	}

	.rd-search {
		position: absolute;
		top: 100%;
		right: 0;
		width: 200px;
		opacity: 1;
		visibility: visible;
		transition: .3s;
		z-index: 2;
	}

	// RD search offset
	* + .rd-navbar-search {
		margin-left: $navbar-static-nav-indent;
	}

	.rd-navbar-search-toggle {
		display: inline-flex;
		@include toggle-icons-via-rotation(32px, 32px, 26px, '\f43b', '\f24c', 'Material Design Icons', 'Material Design Icons');
		color: $rd-navbar-nav-color;

		&:hover {
			color: $rd-navbar-nav-hover-color;
		}
	}

	@include media-breakpoint-up(xl) {
		.rd-nav-item + .rd-nav-item {
			margin-left: 45px;
		}
	}

	// RD navbar Clone
	&.rd-navbar--is-clone {
		display: block;
		transform: translate3d(0, -100%, 0);

		&.rd-navbar--is-stuck {
			transform: translate3d(0, 0, 0);
		}
	}

	// RD Navbar Stuck || Clone
	&.rd-navbar--is-stuck,
	&.rd-navbar--is-clone {
		position: static;
		left: 0;
		top: 0;
		right: 0;
		z-index: $zindex-rd-navbar;
		background: $rd-navbar-background;
		will-change: transform;
	}

	// RD Navbar States
	.rd-navbar--has-dropdown {
		position: relative;
	}
}
