/*
*
* Typography
*/

//
// Body
//
body {
	font-family: $font-family-base;
	font-size: 14px;
	line-height: 1.7;
	font-weight: $font-weight-base;
	color: $body-color;
	background-color: $body-bg;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
	letter-spacing: .028em;

	@include media-breakpoint-up(md) {
		font-size: $font-size-base;
		line-height: $line-height-base;
	}
}

//
// Headings
//
#{headings()} {
	margin-top: 0;
	margin-bottom: 0;
	color: $headings-color;
	font-family: $headings-font-family;
	font-weight: 700;

	a {
		color: inherit;

		&:hover {
			color: $primary;
		}
	}

	span {
		display: inline-block;
	}

	span[data-toggle='modal'] {
		border-bottom: 2px dashed rgba($primary, .3);
		cursor: pointer;
	}

	> span.icon {
		display: inline-block;
		margin-right: 5px;
	}
}

// {0 - 768px} <= 40px
// {768px - 992px} <= 62px
h1,
.heading-1 {
	font-size: 42px;
	line-height: $h1-line-height;
	letter-spacing: 0;
	word-spacing: -5px;

	@include media-breakpoint-up(sm) {
		font-size: 60px;
	}

	@include media-breakpoint-up(md) {
		font-size: 90px;
	}

	@include media-breakpoint-up(xl) {
		font-size: $h1-font-size;
	}
}

// {0 - 768px} <= 36px
// {768px - 992px} <= 52px
h2,
.heading-2 {
	font-weight: 300;
	font-size: 30px;
	line-height: 1.4;
	letter-spacing: 0;

	@include media-breakpoint-up(sm) {
		font-size: 32px;
	}

	@include media-breakpoint-up(md) {
		font-size: 48px;
	}

	@include media-breakpoint-up(xl) {
		font-size: $h2-font-size;
		line-height: $h2-line-height;
	}
}

// {0 - 768px} <= 32px
// {768px - 992px} <= 44px
h3,
.heading-3 {
	font-size: 24px;
	line-height: 1.3;
	letter-spacing: 0;

	@include media-breakpoint-up(md) {
		font-size: 30px;
		line-height: 1.4;
	}

	@include media-breakpoint-up(xl) {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}

	.big {
		font-size: 2em;
		line-height: 1;
	}
}

// {0 - 768px} <= 26px
// {768px - 992px} <= 36px
h4,
.heading-4 {
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: 0;
	font-family: $headings-font-family-secondary;
	font-weight: $font-weight-base;

	@include media-breakpoint-up(xl) {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
	}

	&.h4-smaller {
		font-size: 19px;

		@include media-breakpoint-up(xl) {
			font-size: 22px;
			line-height: (32 / 22);
		}
	}
}

// {0 - 768px} <= 22px
// {768px - 992px} <= 28px
h5,
.heading-5 {
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: .01em;
	font-family: $headings-font-family-secondary;
	font-weight: $headings-font-weight-secondary;

	@include media-breakpoint-up(md) {
		font-size: $h5-font-size;
		line-height: $h5-line-height;
	}
}

// {0 - 768px} <= 18px
// {768px - 992px} <= 22px
h6,
.heading-6 {
	font-size: 14px;
	line-height: 1.8;
	letter-spacing: .2em;
	text-transform: uppercase;
	color: $gray-300;
	font-family: $headings-font-family-secondary;
	font-weight: $headings-font-weight-secondary;

	@include media-breakpoint-up(md) {
		font-size: $h6-font-size;
		line-height: $h6-line-height;
	}
}

.title-decorated {
	position: relative;
	padding-left: 40px;
	text-align: left;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: .8em;
		width: 30px;
		border-bottom: 1px solid;
	}

	@include media-breakpoint-up(md) {
		padding-left: 70px;

		&::before {
			width: 50px;
			top: .7em;
			border-bottom-width: 2px;
		}
	}
}

.title-decorated-lg {
	@include media-breakpoint-up(md) {
		padding-left: 80px;

		&::before {
			width: 62px;
		}

		& + p {
			margin-top: 32px;
		}
	}
}

//
// Emphasis
//
small,
.small {
	display: block;
	font-size: $font-size-sm;
	line-height: $line-height-sm;
}

mark,
.mark {
	padding: 3px 5px;
	color: $white;
	background: $primary;
}

strong {
	font-weight: 700;
	color: $gray-400
}

// Big text
.big {
	font-size: $font-size-lg;
	line-height: $line-height-lg;
	font-weight: $font-weight-base;
	color: $gray-800;
	letter-spacing: -.001em;
}

.lead {
	font-size: $lead-font-size;
	line-height: $lead-line-height;
	font-weight: $lead-font-weight;
}

code {
	padding: $code-padding-y $code-padding-x;
	border-radius: 0;
	font-size: $code-font-size;
	color: $code-color;
	background: $code-bg;
}

.text-large {
	font-family: $font-family-base;
	font-size: 30px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 1.2;
	text-transform: uppercase;

	@include media-breakpoint-up(sm) {
		font-size: 55px;
	}

	@include media-breakpoint-up(md) {
		font-size: 68px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 80px;
	}

	@include media-breakpoint-up(xl) {
		font-size: 100px;
	}
}

%context-dark {
	.text-large {
		color: $white;
	}
}

.text-extra-large {
	font-size: 100px;
	line-height: .8;
	font-weight: 800;

	@include media-breakpoint-up(md) {
		font-size: 130px;
		line-height: .7;
	}

	@include media-breakpoint-up(lg) {
		font-size: 150px;
	}

	@include media-breakpoint-up(xxl) {
		font-size: 180px;
	}
}

.wow-outer {
	span {
		display: block;
	}

	.wow > span {
		display: inline;
	}
}

//
// Paragraph
//
p {
	[data-toggle='tooltip'] {
		padding-left: .25em;
		padding-right: .25em;
		color: $primary;
	}

	[style*='max-width'] {
		display: inline-block;
	}

	a {
		color: inherit;
	}
}

p .text-width-1 {
	display: inline-block;
	max-width: 730px;
}

.text-width-2 {
	display: inline-block;
	max-width: 350px;
}

//
// Colors
//
html .page {
	.text-primary {
		color: $primary;
	}
}

.page {
	.text-danger {
		color: $danger-color;
	}

	.text-gray-700 {
		color: $gray-700;
	}

	.text-color-1 {
		color: $color-1;
	}
}

%context-dark {
	.text-gray-700 {
		color: $white;
	}
}
