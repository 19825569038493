.tm-pg_front_gallery  {
	padding: 0;
}

.lg-outer .lg-icon.lg-toogle-thumb, .lg-outer .lg-icon.lg-close, .lg-outer .lg-icon.lg-autoplay-button, .lg-outer .lg-icon.lg-download, .lg-outer .lg-icon.lg-fullscreen {
	background-color: transparent;
	transition: 0.3s all ease;

	&::after {
		color: #999999;
	}

	&:hover {
		background-color: transparent;

		&::after {
			color: #ffffff;
		}
	}
}

.lg-outer .lg-actions .lg-icon {

	&.lg-next {
		&::before {
			font-family: lg;
			content: '\e095';
		}
	}

	&.lg-prev {
		&::after {
			font-family: lg;
		    content: '\e094';
		}
	}

	&.lg-prev,
	&.lg-next {
		&::after {
			background-color: rgba(0, 0, 0, 0.45);
		    border-radius: 2px;
		    color: #999999;
		    cursor: pointer;
		    display: block;
		    font-size: 22px;
		    margin-top: -10px;
		    padding: 8px 10px 9px;
		    z-index: 108000;
		    border: none;
		    outline: 0;
		}
		&::before {
			background-color: rgba(0, 0, 0, 0.45);
		    border-radius: 2px;
		    color: #999999;
		    cursor: pointer;
		    display: block;
		    font-size: 22px;
		    margin-top: -10px;
		    padding: 8px 10px 9px;
		    z-index: 108000;
		    border: none;
		    outline: 0;
		}
		&:hover {
			&::before,
			&::after {
				color: #ffffff;
			}
		}
	}
}

.lg-outer .lg-toolbar {
	margin: 0;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
	margin-left: 0;
	border: 2px solid #ffffff;
    border-radius: 4px;

    &.active,
    &:hover {
    	border-color: #c5a15b;
    }
}

.max-width-730 {
	max-width: 730px;
}

.block-center {
	margin-left: auto;
	margin-right: auto;
}

.contact-section-wrapper {
	overflow: hidden;
}

.google-map-fluid-wrapper {
	@media (min-width: 1280px) {
		.elementor-widget-google_maps {
			height: 100%;
		    position: absolute;
		    left: 0;
	        right: -73.5%;
		    bottom: 0;
		    top: 0;

		    @media (max-width: 1440px) {
	    	    right: -26.5%;
		    }

		    iframe {
		    	height: 100%;
			    position: absolute;
			    left: 0;
			    right: 0;
			    bottom: 0;
			    top: 0;
		    }
		}
	}
}

.wpcf7-form {
	br,
	p:empty {
		display: none;
	}

	div.wpcf7-response-output {
		position: absolute;
		z-index: 999;
	    background-color: #fff;
	}

	span.wpcf7-not-valid-tip {
		z-index: 11;
	    margin-top: 3px;
	    font-size: 10px;
	    font-weight: 300;
	    line-height: 12px;
	    letter-spacing: 0;
	    color: #dc0000;
	    transition: .3s;
	    top: -20px;
	}
	.form-input.wpcf7-not-valid {
		border-color: #dc0000;
	}
	.wow-outer .wow > span {
		display: block;
	}
}

.elementor-icon-box-content {
	h4 + p {
		margin-top: 16px !important;
	}
}

.icon-box-left-align {
	&.elementor-widget-icon-box .elementor-icon-box-wrapper {
		@media (min-width: 768px) {
			text-align: left;
		}
	}
}

.cs-share {
	.cs-share__list {
		.cs-share__item {
			.cs-share__link {
				text-align: center;
				width: 40px;
			    height: 40px;
			    line-height: 40px;
				border-radius: 50%;
				color: #aeb1be;
				position: relative;
				display: inline-block;

				&::before {
					border: none;
					position: relative;
				    display: inline-block;
				    font-weight: 400;
				    font-style: normal;
				    speak: none;
				    text-transform: none;
					width: auto;
					height: auto;
					border: none;
					line-height: inherit;
				}

				&::after {
					content: '';
				    position: absolute;
				    top: 0;
				    right: 0;
				    bottom: 0;
				    left: 0;
				    z-index: -1;
				    border-radius: inherit;
				    pointer-events: none;
				    opacity: 0;
				    transform: scale3d(0, 0, 0);
				    transition: .22s;
				}

				&[href*='facebook']::after {
					background: #2059a0;
				}

				&[href*='twitter']::after {
					background: #00aae6;
				}

				&[href*='google']::after {
					background: #ff3807;
				}

				&[href*='linkedin']::after {
					background: #2881a8;
				}

				&[href*='pinterest']::after {
					background: linear-gradient(to top, #f15823 0%, #a7058e 100%);
				}

				&:hover {
					color: #fff;

					&::after{
						opacity: 1;
					    transform: scale3d(1, 1, 1);
					}
				}
			}
		}
	}
}

#comments {
	ol.comment-list li .comment-body {
		padding: 20px 0;
		border-bottom: 1px solid #e8e9ee;
	}
	ol.comment-list {
		margin-top: 50px;
	}
	ol.comment-list li.comment div.vcard img.avatar {
		border-radius: 50%;
	}
	ol.comment-list li.comment footer.comment-meta {
		font-size: 16px;
	    line-height: 1.5;
	    color: #c5a15b;

	    .fn {
	    	font-weight: normal;
	    }
	}
	ol.comment-list li.comment ol.children {
	    padding-left: 75px;
	}
}

#respond {
	margin-top: 70px;

	.row + .row {
		margin-top: 10px;
	}
}

#sidebar {
	.widget {
		> h3 {
			margin-bottom: 20px;
		}
	}

	.search {
		.searching {
			width: 100%;
		    padding: 10px 15px;
		    border: 2px solid #f2f2f2;
		}
		.button {
		    margin-top: 20px;
		}
	}
}

.no-results {
	.search {
		.searching {
			width: 100%;
		    padding: 10px 15px;
		    border: 2px solid #f2f2f2;
		    margin-top: 20px;
		}
		.button {
			margin-top: 20px;
		}
	}
}

.elementor-shortcode {
	@media (min-width: 1024px) and (max-width: 1199px) {
		.rd-form.rd-mailform {
		    margin-left: 20px;
		}
	}
}

.cherry-team {
	.team-member-name {
		margin-bottom: 20px;
	}
}